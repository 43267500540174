import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const FooterComponent = () => {
  const dataReduxGlobal = useSelector((state) => state.global.configuration);
  const dataRedux = useSelector((state) => state.buttonReserve);
  return (

    <footer>
      <div className='footer-container' style={{ marginBottom: `${dataRedux.height}px` }}>
        <div className='logo-container'>
          <span className='logo'></span>
        </div>
        <div className='item locations'>
          <p className='title'>UBICACIÓN</p>
          <p>Isidoro Dubournais 0685</p>
          <p>El Quisco</p>
          <p>Región de Valparaíso.</p>
          <p>¿Cómo llegar?</p>
        </div>
        <br></br>
        <div className='item links'>
          <p className='title'>ENLACES</p>
          <Link to="/valores"> <p>Convenios con empresas</p></Link>
          <Link to="/servicios"><p>Servicios y entretenimiento</p></Link>
          <Link to="/valores"><p>Lista de promociones</p></Link>
          <a 
            href="https://www.google.com/maps/place/Av.+Isidoro+Dubournais+0685,+2701947+El+Quisco,+Valpara%C3%ADso/@-33.4025619,-71.6942524,17z/data=!3m1!4b1!4m6!3m5!1s0x966216c8f4f8fb47:0x82abea5e61a3600f!8m2!3d-33.4025619!4d-71.6942524!16s%2Fg%2F11tf_3qtv4?entry=ttu&g_ep=EgoyMDI1MDMwMi4wIKXMDSoASAFQAw%3D%3D" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <p>Explore el litoral central</p>
          </a>

        </div>
        <br></br>
        <div className='item contact'>
          <p className='title'>CONTÁCTANOS</p>
          <p>De lunes a domingo </p>
          <p>9:00 am a 8:00 pm</p>
          <p> Whatsapp {dataReduxGlobal.whatsapp}</p>
          <p>Teléfono (Recepción) {dataReduxGlobal.whatsapp2}</p>
        </div>
      </div>
    </footer>

  )
}
